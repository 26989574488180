import '../styles/pages/home.scss';
import * as React from "react"
import type {HeadFC} from "gatsby"
import PageService, {HomePageComponents} from "../services/pageService";
import {useEffect, useMemo, useState} from "react";
import {
    BigLogoImage, CrossIcon, CrownImage, LoadingGif, AppStoreV2, apple, arrRight2
} from "../images";
import {ApiComponentType} from "../types/ApiComponent";
import HomeOverview from "../components/HomeOverview";
import HomeInfoBlock from "../components/HomeInfoBlock";
import LearnBlockList from "../components/LearnBlockList";
import HomeStatistics from "../components/HomeStatistics";
import HomeScreenshots from "../components/HomeScreenshots";
import {Link} from "gatsby";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import MainFooter from '../components/MainFooter';
import MobileNavigation from '../components/MobileNavigation';

const Test1Page = () => {
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState<HomePageComponents>([]);
    const [showMenu, setShowMenu] = useState(false);

    console.log('init')

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    }

    useEffect(() => {
        PageService.getCourseById(1).then((result) => {
            setData(result.data.data.attributes.Content)
        }).catch(() => {
        }).finally(() => {
            setLoading(false)
        });
    }, []);

    const renderContent = useMemo(() => {
        return data.map((content, index) => {
            switch (content.__component) {
                case ApiComponentType.HOME_OVERVIEW: {
                    return (
                        <HomeOverview key={`item-${index}`} data={content}/>
                    )
                }

                case ApiComponentType.HOME_INFO_BLOCK: {
                    return (
                        <HomeInfoBlock key={`item-${index}`} data={content}/>
                    )
                }

                case ApiComponentType.LEARN_BLOCK_LIST: {
                    return (
                        <LearnBlockList key={`item-${index}`} data={content}/>
                    )
                }

                case ApiComponentType.STATISTICS_GRID: {
                    return (
                        <HomeStatistics key={`item-${index}`} data={content}/>
                    )
                }

                case ApiComponentType.APP_SCREENSHOTS: {
                    return (
                        <HomeScreenshots key={`item-${index}`} data={content}/>
                    )
                }
            }
        })
    }, [data])

    return (
        <main>
            {
                isLoading && (
                    <div className="loadWrap">
                        <div className="loadWrapLogo">
                            <img width={180} src={BigLogoImage} alt=""/>
                            <div className="loadWrapText">
                                <img width={16} src={LoadingGif} alt=""/>
                                Loading..
                            </div>
                        </div>
                    </div>
                )
            }
            <header>
                <div className="header container">
                    <div className="headerLogo">
                        <Link to="/">
                            <img src={BigLogoImage} width={200} alt=""/>
                        </Link>
                    </div>
                    <button onClick={toggleMenu} className="mobileBurger">
                        <span></span><span></span>
                    </button>
                    <div className="headerNav">
                        <nav>
                            <ul>
                                <li><Link to="/#tools">Tools</Link></li>
                                <li><Link to="/list">Skills development</Link></li>
                                <li><Link to="/#preorder">Pre-order</Link></li>
                                <li><AnchorLink stripHash to="/#contacts">Contacts</AnchorLink></li>
                                <li><a href="https://aeer-partners.com/">Affiliate</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>

            <div className="content">
                {renderContent}

                {/* <div className="buttonBlock" id={'preorder'}>
                    <Link to="https://apps.apple.com/us/app/aeer-education-productivity/id1630377140?l=ru" className="btn btn-primary">
                        <img src={CrownImage} className="btn-img" width={21} alt=""/>
                        <span>Try AEER for FREE</span>
                    </Link>
                </div> */}
                <div className="buttonBlock2" id={'preorder'}>
                    <Link to="https://apps.apple.com/us/app/aeer-education-productivity/id1630377140?l=ru" className="btnv2 btn-primary">
                        <img src={apple} className="btn-img apple" width={24} alt=""/>
                        <span>Download on the App Store</span>
                        <img src={arrRight2} className="btn-img" width={30} alt=""/>
                    </Link>
                </div>
            </div>

            <MainFooter />

            {showMenu && (
                <MobileNavigation onClick={toggleMenu} relation="Home" />
            )}
        </main>
    )
}

export default Test1Page

export const Head: HeadFC = () => <title>AEER Platform</title>
